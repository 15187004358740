import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import AuditIcon from '@material-ui/icons/Security';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import MeasureIcon from '@material-ui/icons/BarChart'
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import DocumentIcon from '@material-ui/icons/Description';
import AssistantIcon from '@material-ui/icons/Assistant'
import CategoryIcon from '@material-ui/icons/AccountTree'
import EcoIcon from '@material-ui/icons/Eco';
import ProjectIcon from '@material-ui/icons/EmojiNature'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { compose, Authorization } from 'utils';
import withStyles from 'styles';
import { consume, provide, TokensContext, VersionsContext } from 'contexts';
import Divider from '@material-ui/core/Divider';

export class Nav extends Component {
  state = { drawerOpen: false };

  componentDidMount() {
    this.props.versions.actions.getTag();
  }

  handleRequestOpen = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render = () => (
    <Fragment>
      <AppBar position="sticky">
        <Toolbar>
          <Typography
            className={this.props.classes.title}
            variant="h6"
            color="inherit"
          >
            <Link to="/">Green KPI</Link>
          </Typography>
          <IconButton
            color="inherit"
            aria-label="Menu"
            onClick={() => this.setState({ drawerOpen: true })}
          >
            <MenuIcon />
          </IconButton>
          <IconButton color="inherit" onClick={this.handleRequestOpen}>
            <PersonIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        id="simple-menu"
        anchorEl={this.state.anchorEl}
        open={!!this.state.open}
        onClose={this.handleRequestClose}
      >
        <Link to="/profile">
          <MenuItem>My Profile</MenuItem>
        </Link>
        <MenuItem onClick={this.props.tokens.actions.destroy}>Logout</MenuItem>
      </Menu>
      <Drawer
        anchor="right"
        classes={{ paper: this.props.classes.drawer }}
        open={this.state.drawerOpen}
        onClose={() => this.setState({ drawerOpen: false })}
        onClick={() => this.setState({ drawerOpen: false })}
      >
	  { Authorization.admin &&
	      <Link to='/framework_templates'>
                <ListItem button>
                  <ListItemIcon>
                    <AssistantIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Framework Templates" />
                </ListItem>
              </Link>
	  }
        <Link to="/framework">
         <ListItem button>
           <ListItemIcon>
             <ProjectIcon/>
           </ListItemIcon>
           <ListItemText primary="Framework Generator" />
         </ListItem>
        </Link>

        <Link to="/categories">
          <ListItem button>
            <ListItemIcon>
              <CategoryIcon />
            </ListItemIcon>
            <ListItemText primary="Categories" />
          </ListItem>
        </Link>

        <Link to="/measures">
          <ListItem button>
            <ListItemIcon>
              <MeasureIcon />
            </ListItemIcon>
            <ListItemText primary="KPI Measures" />
          </ListItem>
        </Link>

        <Link to="/projects">
          <ListItem button>
            <ListItemIcon>
              <ProjectIcon />
            </ListItemIcon>
            <ListItemText primary="Projects" />
          </ListItem>
        </Link>

        <Link to="/carbon_calculator">
          <ListItem button>
            <ListItemIcon>
              <EcoIcon />
            </ListItemIcon>
            <ListItemText primary="Carbon Calculator" />
          </ListItem>
        </Link>
        <Divider />
        {Authorization.admin && (
          <Link to="/users">
            <ListItem button>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
          </Link>
        )}
        {Authorization.admin && (
          <Link to="/apidocs">
            <ListItem button>
              <ListItemIcon>
                <DocumentIcon />
              </ListItemIcon>
              <ListItemText primary="API Docs" />
            </ListItem>
          </Link>
        )}
        {Authorization.admin && (
          <Link to="/audits">
            <ListItem button>
              <ListItemIcon>
                <AuditIcon />
              </ListItemIcon>
              <ListItemText primary="Audits" />
            </ListItem>
          </Link>
        )}
        <div className={this.props.classes.version}>
          API {this.props.versions.tag} / CLIENT {process.env.REACT_APP_VERSION}
        </div>
      </Drawer>
    </Fragment>
  );
}

const styles = {
  title: {
    flex: 1
  },
  drawer: {
    width: 200
  },
  version: {
    position: 'absolute',
    bottom: 0,
    right: 10,
    fontSize: 11,
    color: '#9a9a9a'
  }
};
export default compose(
  consume(TokensContext),
  provide(VersionsContext),
  withStyles(styles)
)(Nav);
