import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {ControlledForm, CenteredCard, ErrorBanner, PromiseButton} from 'components'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography'
import { TokensContext, SnackbarContext, consume } from 'contexts'
import withStyles from 'styles'

export class SignIn extends Component{

  signIn = async () => {
    try{
      await this.props.tokens.actions.create(this.state)
      const location = (this.props.savedLocation && !this.props.savedLocation.match(/^\/?tokens\//)) ?
        this.props.savedLocation :
        '/'
      this.props.history.push(location)
      this.props.snackbar.actions.show('Signed in succesfully')
    }catch(err){
      this.props.snackbar.actions.show('Signed in failed')
    }
  }

   errorFor = key => {
    try{
      return this.props.tokens.errors.create.meta[key].join(', ')
    }catch(err){}
  }

  render = () =>
    <CenteredCard className={this.props.classes.white}>
      <ControlledForm
        data={this.state} errors={{email: this.errorFor('email'), password: this.errorFor('password')}} onChange={state => this.setState(state)} onSubmit={this.signIn}>
        <CardContent>
          <Typography variant='h6'>Sign in</Typography>
          {this.props.tokens.errors.create && <ErrorBanner>{this.props.tokens.errors.create.title}</ErrorBanner>}
          <FormGroup>
            <TextField name='email'   type='text'/>
            <TextField name='password'type='password'/>
              <FormControlLabel
                control={
                  <ControlledForm data={this.state} onChange={state => this.setState(state)}>
                  <Checkbox name="rememberMe" value="checkedA"/>
                  </ControlledForm>
                }
                label="Remember Me"
              />
          </FormGroup>
        </CardContent>
        <CardActions>
          <PromiseButton fullWidth variant='contained' color='primary' type='submit'>Submit</PromiseButton>
        </CardActions>
        <Link className='link-small' to='/tokens/forgot'>Forgot password</Link>
      </ControlledForm>
    </CenteredCard>
}

const styles = {
  white: {
    backgroundColor: '#ffffff'
  }
}

export default withStyles(styles)(consume(TokensContext, SnackbarContext)(SignIn))