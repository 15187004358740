import React, { Component, Fragment } from 'react'
import instanceForm from 'containers/shared/instanceForm'
import dependsOn from 'containers/shared/dependsOn'
import {CategoryTree, ControlledForm, ErrorBanner, PageContainer, PromiseButton, Pagination} from 'components'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import CheckBox from '@material-ui/core/Checkbox'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { compose } from 'utils'
import withStyles from 'styles'
import { provide, ProjectsContext, CategoriesContext, MeasuresContext } from 'contexts'

export class Form extends Component {
  toggleMeasure = (id, callback) => ({target: {checked}}) => {
    callback()
    this.props.onFormDataChange({
      ...this.props.formData,
      measures: checked ?
        [...(this.props.formData.measures || []), {type: 'measures', id}] :
        (this.props.formData.measures || []).filter(({id: check_id}) => id !== check_id)
    })
  }

  changePage = page => {
    const {measures, categories} = this.props
    fetchMeasures({measures, categories, page})
  }

  renderMeasure = ({name, id}, {expand}) => {
    let checked = (this.props.formData.measures || []).find(({id: check_id}) => check_id === id) !== undefined
    return <ListItem key={`measures/${id}`}>
      <ListItemIcon>
        <CheckBox checked={checked} onChange={this.toggleMeasure(id,expand)}/>
      </ListItemIcon>
      <ListItemText>
        {name}
      </ListItemText>
    </ListItem>
  }

  render = () => {
    const {formData, errors, errorStrings, onFormDataChange, onSave, editMode, measures = {}, categories = {}, ...props} = this.props
    return (
      <PageContainer className={this.props.classes.card}>
        <Typography variant='h5'>{editMode ? `Edit Project - ${formData.name}` : 'Create a new Project'}</Typography>
        <ControlledForm data={formData} errors={errors} onChange={onFormDataChange} onSubmit={onSave}>
          <ErrorBanner>{errorStrings}</ErrorBanner>
          <TextField fullWidth name='name'/>
          {(this.props.categories.count > 0 || this.props.measures.length > 0) &&
            <Fragment>
              <Typography variant='h6'>Select KPI Measures</Typography>
              <Pagination
                totalPages={Math.max(this.props.categories.totalPages, this.props.measures.totalPages)}
                page={Math.max(this.props.categories.page, this.props.measures.page)}
                onPageSelected={this.changePage}
              />
              <CategoryTree
                {...props}
                root={{measures: measures.list, categories: categories.list}}
                autoexpand={({measures: theseMeasures}) => !!theseMeasures.find(
                  ({id: idA}) => this.props.formData.measures?.find(({id: idB}) => idA === idB)
                )}
                extraChildren={({measures: theseMeasures = []}, options) =>
                  theseMeasures.map(measure => this.renderMeasure(measure,options))
                }
              />
            </Fragment>
          }
        <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
        </ControlledForm>
      </PageContainer>
    )
  }
}

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  },
  nested: {
    paddingLeft: '1em',
  }
}

const fetchMeasures = ({measures, categories, page}) => {
  return categories.actions.index({
    filter: { top_level: 'true' },
    include: 'subcategories,measures,subcategories.measures',
    fields: {
      categories: 'name,subcategories,measures',
      measures: 'name'
    },
    page: page
  })
}

const fetchDependencies = async ({projects, measures, categories, id, editMode}) => {
  if (editMode) {
    await projects.actions.show(id, {include: 'measures'})
  }
  await fetchMeasures({measures, categories, page: 1})
}

export default compose(
  dependsOn(fetchDependencies),
  instanceForm('projects', {linked: ['measures']}),
  withStyles(styles),
  provide(ProjectsContext, MeasuresContext, CategoriesContext),
)(Form)