import React, { Component } from 'react'
import { CenteredCard, ErrorBanner } from 'components'
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography'
import { SnackbarContext, TokensContext, consume } from 'contexts'
import withStyles from 'styles'

export class Confirm extends Component{

  componentDidMount = () => {
    this.submit()
  }

  get token(){
    return window.location.search.replace(/^.*?token=([^$&]+).*/,'$1')
  }

  submit = async () => {
    await this.props.tokens.actions.confirm({token: this.token})
    await this.props.tokens.actions.verify()
    this.props.snackbar.actions.show("Your account has been confirmed")
    this.props.history.push('/')
  }

  render = () =>
    <CenteredCard className={this.props.classes.white}>
      <CardContent>
        {this.props.tokens.errors.confirm && <ErrorBanner>{this.props.tokens.errors.confirm.title}</ErrorBanner>}
        <Typography type='h5'>Confirming your account...</Typography>
      </CardContent>
    </CenteredCard>
}

const styles = {
  white: {
    backgroundColor: '#ffffff'
  }
}

export default withStyles(styles)(consume(TokensContext, SnackbarContext)(Confirm))