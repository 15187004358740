import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { CarbonCalculator, TYPE_OF_CARS, TYPE_OF_FUEL } from './CarbonCalculator';
import { Select } from 'components';
import Line from './Line';
import { makeStyles } from '@material-ui/core/styles';

const initState = {
  compostingKg: '',
  drivingToCompostingFacilities: '',
  carTypeFacility: '',
  fuelTypeFacility: '',
  drivingToCollectMulch: '',
  carTypeMulch: '',
  fuelTypeMulch: ''
};

export default function Form() {
  const classes = useStyles();
  const carbonCalculator = new CarbonCalculator();
  const [formAttributes, setFormAttributes] = useState(initState);
  const carbonEmissionFromDrivingFacilities = carbonCalculator.carbonDioxideEmission(formAttributes.drivingToCompostingFacilities, formAttributes.carTypeFacility, formAttributes.fuelTypeFacility);
  const carbonEmissionFromDrivingToCollectMulch = carbonCalculator.carbonDioxideEmission(formAttributes.drivingToCollectMulch, formAttributes.carTypeMulch, formAttributes.fuelTypeMulch);
  const totalCarbonEmissionFromDriving = carbonEmissionFromDrivingFacilities + carbonEmissionFromDrivingToCollectMulch;

  const handleChange = ({ target }) => {
    setFormAttributes({ ...formAttributes, [target.id || target.name]: target.value });
  };

  return (
    <form noValidate autoComplete="off" className={classes.container}>
      <TextField fullWidth id="compostingKg" label="Composting Kg" value={formAttributes.compostingKg} onChange={handleChange} />
      <div className={classes.columns}>
        <div className={classes.column}>

        <TextField
          fullWidth
          id="drivingToCompostingFacilities"
          label="Driving to Composting facilities and return in km (return)"
          value={formAttributes.drivingToCompostingFacilities}
          onChange={handleChange}
        />
        <Select
          fullWidth
          id="carTypeFacility"
          label="Type of car"
          onChange={handleChange}
          inputProps={{
            name: 'carTypeFacility',
            id: 'carTypeFacility'
          }}
        >
          {Object.keys(TYPE_OF_CARS).map((type) => {
            return (
              <MenuItem id={type} value={type}>
                {TYPE_OF_CARS[type]}
              </MenuItem>
            );
          })}
        </Select>
        <Select
          fullWidth
          id="fuelTypeFacility"
          label="Fuel type"
          onChange={handleChange}
          inputProps={{
            name: 'fuelTypeFacility',
            id: 'fuelTypeFacility'
          }}
        >
          {Object.keys(TYPE_OF_FUEL).map((type) => {
            return (
              <MenuItem id={type} value={type}>
                {TYPE_OF_FUEL[type]}
              </MenuItem>
            );
          })}
        </Select>
      </div>
      <div className={classes.column}>

        <TextField id="drivingToCollectMulch" fullWidth label="Driving to collect mulch and return in km" value={formAttributes.drivingToCollectMulch} onChange={handleChange} />
        <Select
          fullWidth
          id="carTypeMulch"
          label="Type of car"
          onChange={handleChange}
          inputProps={{
            name: 'carTypeMulch',
            id: 'carTypeMulch'
          }}
        >
          {Object.keys(TYPE_OF_CARS).map((type) => {
            return (
              <MenuItem id={type} value={type}>
                {TYPE_OF_CARS[type]}
              </MenuItem>
            );
          })}
        </Select>
        <Select
          fullWidth
          id="fuelTypeMulch"
          label="Fuel type"
          onChange={handleChange}
          inputProps={{
            name: 'fuelTypeMulch',
            id: 'fuelTypeMulch'
          }}
        >
          {Object.keys(TYPE_OF_FUEL).map((type) => {
            return (
              <MenuItem id={type} value={type}>
                {TYPE_OF_FUEL[type]}
              </MenuItem>
            );
          })}
        </Select>
        </div>
      </div>
      <br/>
      <br/>
      <div className={classes.block}>
        <Typography className={classes.blockTitle}>Potential CO<sub>2</sub>-e (kg) Saving</Typography>
        <Line value={carbonCalculator.nitrogenous(formAttributes.compostingKg)} label="Nitrogenous" />
        <Line value={carbonCalculator.phosphorus(formAttributes.compostingKg)} label="Phosphorus" />
        <Line value={carbonCalculator.version1(formAttributes.compostingKg)} label="Version 1 (IPCC)" />
        <Line value={carbonCalculator.version2(formAttributes.compostingKg)} label="Version 2 (Food Waste Calculator)" />
        <Line value={carbonCalculator.version3(formAttributes.compostingKg)} label="Version 3 (Eliazer CH4)" />
        <Line value={carbonCalculator.version4(formAttributes.compostingKg)} label="Version 4 (Zhang et al 2007)" />
      </div>

      <div className={classes.block}>
        <Typography className={classes.blockTitle}>Emissions of CO<sub>2</sub>-e (kg) for Driving</Typography>
        <Line className={classes.red} value={carbonEmissionFromDrivingFacilities} label="Driving to composting" />
        <Line className={classes.red} value={carbonEmissionFromDrivingToCollectMulch} label="Driving to mulch" />
        <Line className={classes.red} value={totalCarbonEmissionFromDriving} label="Total" />
      </div>

      <div className={classes.block}>
        <Typography className={classes.blockTitle}>Total Potential CO<sub>2</sub>-e (kg) Savings</Typography>
        <Line value={carbonCalculator.totalSavingLowest(formAttributes.compostingKg, totalCarbonEmissionFromDriving)} label="Lowest" />
        <Line value={carbonCalculator.totalSavingHighest(formAttributes.compostingKg, totalCarbonEmissionFromDriving)} label="Highest" />
        <Line value={carbonCalculator.totalSavingAverage(formAttributes.compostingKg, totalCarbonEmissionFromDriving)} label="Average" />
      </div>
    </form>
  );
}

const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    margin: '0 auto',
    backgroundColor: '#c6c3b4'
  },
  blockTitle: {
    fontWeight: 'bold',
    borderBottom: '1px solid #949494'
  },
  block: {
    paddingTop: '10px',
    paddingBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  red:{
    color: 'red'
  },
  column: {
    width: '50%',
    minWidth: 480,
    float: 'left',
  },
  columns: {
  }
});
