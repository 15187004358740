import React, { Component, Fragment } from 'react'
import { capitalize } from 'utils'
import TextField from '@material-ui/core/TextField'

export default class Form extends Component {
  get kpiType() {
    return this.measure.kpiType
  }

  get measure(){
    return this.props.measure
  }

  onChange = ({target: {value}}) => this.props.onChange({
    target: {
      value: {
        ...this.props.value,
        [this.kpiType]: value
      }
    }
  })

  onChangeLink = ({target: {value}}) => this.props.onChange({
    target: {
      value: {
        ...this.props.value,
        link: value
      }
    }
  })


  renderQualitative = () => <Fragment>
    <TextField
      multiline
      fullWidth
      label={this.measure.name}
      value={this.props.value.qualitative ?? ""}
      onChange={this.onChange}
     />
    <TextField
      multiLine
      fullWidth
      label="Hyperlink"
      value={this.props.value.link ?? ""}
      onChange={this.onChangeLink}
    />
   </Fragment>

  renderQuantitative = () => <TextField
    type='number'
    fullWidth
    label={this.measure.name}
    value={this.props.value.quantitative ?? 0}
    onChange={this.onChange}
   />

  render = () => this[`render${capitalize(this.kpiType)}`]()
}
