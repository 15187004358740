import React, { Component, Fragment } from 'react';
import { CategoryTree, Pagination, FABFixed, ErrorBanner, PageContainer } from 'components';
import dependsOn from 'containers/shared/dependsOn';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { compose, errorStringsFromError } from 'utils';
import indexView, { hasIndexChanged } from 'containers/shared/indexView';
import { provide, consume, SnackbarContext, CategoriesContext } from 'contexts';
import withStyles from 'styles'

export class List extends Component {
  state = {
    page: 1
  };

  showCategory = (id) => () => {
    this.props.history.push(`/categories/${id}`);
  };

  editCategory = (id) => (event) => {
    this.props.history.push(`/categories/${id}/edit`);
    event.stopPropagation();
  };

  deleteCategory = (id) => (event) => {
    this.props.categories.actions
      .destroy({ id })
      .then(this.props.onDependencyUpdate)
      .catch((error) => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')));
    event.stopPropagation();
  };

  get categories() {
    return this.props.categories.list;
  }

  get errors() {
    let errors = [];
    if (this.props.categories.errors.index) {
      errors = errors.concat(this.props.categories.errors.index);
    }
    if (this.props.categories.errors.destroy) {
      errors = errors.concat(this.props.categories.errors.destroy);
    }
    return errors;
  }

  renderActionButtons = ({ id }) => <Fragment>
      <IconButton onClick={this.editCategory(id)}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={this.deleteCategory(id)}>
        <DeleteIcon />
      </IconButton>
    </Fragment>

  renderErrorMessages = () => <ErrorBanner>{errorStringsFromError(this.errors)}</ErrorBanner>;

  render = () => (
    <PageContainer>
      {this.renderErrorMessages()}
      <Pagination totalPages={this.props.categories.totalPages} page={this.props.page} onPageSelected={this.props.onPageChange} style={{}} linkStyle={{}} />
      <CategoryTree
        classes={this.props.classes}
        root={{categories: this.props.categories.list}}
        categoryDecorator={this.renderActionButtons}
        onRowClick={({id}) => this.showCategory(id)}
        prefix
      />
      <Pagination totalPages={this.props.categories.totalPages} page={this.props.page} onPageSelected={this.props.onPageChange} style={{}} linkStyle={{}} />
      <FABFixed color="secondary" onClick={() => this.props.history.push('/categories/new')}>
        <AddIcon />
      </FABFixed>
    </PageContainer>
  );
}

const fetchDependencies = ({ categories, page }) => {
  return categories.actions.index({
    page: page,
    fields: { categories: 'name,subcategories' },
    filter: { topLevel: 'true' },
    include: 'subcategories'
  });
};

const styles = {
  nested: {
    paddingLeft: '1em',
  }
}

export default compose(
  dependsOn(fetchDependencies, hasIndexChanged),
  indexView('categories'),
  withStyles(styles),
  provide(CategoriesContext),
  consume(SnackbarContext)
)(List);
