import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#a92749',
      main: '#a01137',
      dark: '#920f32',
      contrastText: '#fff'
    },
    secondary: {
      main: '#000',
      contrastText: '#fff'
    },
    type: 'light'
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Raleway, Avenir, Helvetica, arial',
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#c6c3b4',
        '& .link-small': {
          margin: 5,
          textAlign: 'center',
          display: 'block',
          color: '#666565',
          fontSize: '0.8rem',
          '&:hover': {
            textDecoration: 'underline'
          }
        }
      }
    },
    MuiCardContent: {
      root: {
        maxWidth: 1000,
        margin: '0 auto',
        width: '100%',
      }
    },
    MuiFormControl: {
      root: {
        marginBottom: '10px'
      }
    },
    MuiButton: {
      root: {
        marginBottom: '10px',
        '&+$root': {
          marginLeft: '10px'
        }
      }
    },
    MuiAccordion: {
      root: {
        '&:before': {
          backgroundColor: null
        }
      }
    }
  }
})