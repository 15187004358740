export class CarbonCalculator {
  DECIMAL_PLACES = 2;

  //https://www.drivingtests.co.nz/resources/fuel-co2-calculator-carbon-dioxide-emissions-in-kg/
  //The approximate CO2 production from 1 litre of fuel-burning
  carbonDioxidePerLiter = {
    diesel: 2.68,
    petrol: 2.31,
    // lpg: 1.51
  };

  //http://www.together-eu.org/docs/102/TOGETHER_Eco-driving_5_Handout_06.pdf
  //Standard fuel consumption(Per 100km)
  fuelConsumptionOfCars = {
    microcar: 4.5,
    subcompact: 5.1,
    compact: 5.3,
    family: 7.0,
    fullsize: 7.4,
    luxury: 9.1,
    sport: 10.6,
    suv: 8.6
  };

  /**
   * @param {?number|string} kg - Composting Kg
   * @returns {number} - kg
   */
  nitrogenous(kg) {
    return round(toNumber(kg) * 0.01 * 4, this.DECIMAL_PLACES);
  }

  /**
   * @param {?number|string} kg - Composting Kg
   * @returns {number} - kg
   */
  phosphorus(kg) {
    return round(toNumber(kg) * 0.01 * 2, this.DECIMAL_PLACES);
  }

  /**
   * Greenhouse gas emission
   * @param {?number|string} kg - Composting Kg
   * @returns {number} - kg
   */
  version1(kg) {
    //0.036 is a standard value from IPCC
    return round(toNumber(kg) * 0.036 * 28, this.DECIMAL_PLACES);
  }

  /**
   * Greenhouse gas emission
   * @param {?number|string} kg - Composting Kg
   * @returns {number} - kg
   */
  version2(kg) {
    //1.9 is a standard value from Food waste calculator
    return round(toNumber(kg) * 1.9, this.DECIMAL_PLACES);
  }

  /**
   * Greenhouse gas emission
   * @param {?number|string} kg - Composting Kg
   * @returns {number} - kg
   */
  version3(kg) {
    //0.215 is a standard value from Eliazer CH4
    return round(toNumber(kg) * 0.215 * 28, this.DECIMAL_PLACES);
  }

  /**
   * Greenhouse gas emission
   * @param {?number|string} kg - Composting Kg
   * @returns {number} - kg
   */
  version4(kg) {
    //0.24865 is a standard value from Zhang et al 2007
    return round(toNumber(kg) * 0.24865 * 28, this.DECIMAL_PLACES);
  }

  /**
   * CO2 emission per provided km, type of car and type of fuel
   * @param {?number|string} km - distance in kilometers
   * @param {string} [carType=family] - type of car. List = [Microcar, Subcompact, Compact, Family, Fullsize, Luxury, SUV]
   * @param {string} [fuelType=petrol]  - type of fuel. List = [Petrol, Diesel]
   * @returns {number} - kg
   */
  carbonDioxideEmission(km, carType = 'family', fuelType = 'petrol') {
    const clearType = (str = '') => str.replace(/[^a-zA-Z]/g,'').toLowerCase();
    const car_type = clearType(carType);
    const fuel_type = clearType(fuelType);
    if(!Object.keys(this.fuelConsumptionOfCars).includes(car_type) || !Object.keys(this.carbonDioxidePerLiter).includes(fuel_type)) return 0

    const fuelConsumption = this.fuelConsumptionOfCars[car_type] || 0;
    const totalAmount = this.totalAmountInLitres(fuelConsumption, toNumber(km));
    //CO2 production from 1 litre of fuel-burning
    const perLitre = this.carbonDioxidePerLiter[fuel_type] || 0;
    return round(totalAmount * perLitre, this.DECIMAL_PLACES);
  }

  /**
   * Total CO2 saving lowest
   * @param {?number|string} kg - Composting Kg
   * @param {?number|string} totalEmission - Total CO2 emission from driving
   * @returns {number}
   */
  totalSavingLowest(kg, totalEmission) {
    return round(this.nitrogenous(kg) + this.phosphorus(kg) + this.version1(kg) - toNumber(totalEmission), this.DECIMAL_PLACES);
  }

  /**
   * Total CO2 saving highest
   * @param {?number|string} kg - Composting Kg
   * @param {?number|string} totalEmission - Total CO2 emission from driving
   * @returns {number}
   */
  totalSavingHighest(kg, totalEmission) {
    return round(this.nitrogenous(kg) + this.phosphorus(kg) + this.version4(kg) - toNumber(totalEmission), this.DECIMAL_PLACES);
  }

  /**
   * Average CO2 saving
   * @param {?number|string} kg - Composting Kg
   * @param {?number|string} totalEmission - Total CO2 emission from driving
   * @returns {number}
   */
  totalSavingAverage(kg, totalEmission) {
    return round((this.nitrogenous(kg) + this.phosphorus(kg) + ((this.version1(kg) + this.version2(kg) + this.version3(kg) + this.version4(kg)) / 4)) - toNumber(totalEmission), this.DECIMAL_PLACES);
  }

  /**
   * The total amount of fuel used in Litres
   * @param {number} [fuelConsumption=0] - Standard fuel consumption(Per 100km)
   * @param {number} [distance=0] - Km (return)
   * @returns {number}
   */
  totalAmountInLitres(fuelConsumption = 0, distance = 0) {
    return Number((fuelConsumption / 100) * distance);
  }
}

export const TYPE_OF_CARS = {
  microcar: 'Microcar (Smart for two)',
  subcompact: 'Subcompact (Twingo)',
  compact: 'Compact (VW Golf - Basic)',
  family: 'Family car (Opel Insigina)',
  fullSize: 'Full size car (Mercedes Class E)',
  luxury: 'Luxury car (Audi A8)',
  suv: 'SUV (Vw Tiguan)'
};

export const TYPE_OF_FUEL = {
  petrol: 'Petrol',
  diesel: 'Diesel'
};

/**
 * round number
 * @param {number} [value=0] - value to be rounded
 * @param {number} [decimalPlaces=0] decimalPlaces - decimal places
 */
export function round(value = 0, decimalPlaces = 0) {
  const rank = Math.pow(10, decimalPlaces);
  return Math.round(value * rank * (1 + Number.EPSILON)) / rank;
}

/**
 * convert to a number, if it's not convertible will return 0
 * @param {any} val
 * @return {number}
 */
export function toNumber(val){
  const value = Number(val);
  if(isNaN(value)) return 0;
  return value;
}
