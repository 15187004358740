import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import dependsOn from 'containers/shared/dependsOn';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { PageContainer } from 'components';
import { provide, MeasuresContext } from 'contexts';

export class Show extends Component {
  get measure() {
    return this.props.measures.selected;
  }

  render = () => (
    <PageContainer>
      <Typography variant="h4">Measure - {this.measure.name}</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: 30 }} variant="head">
              Actions
            </TableCell>
            <TableCell>
              <Link to={`/measures/${this.measure.id}/edit`}>
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Name</TableCell>
            <TableCell>{String(this.measure.name || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Category</TableCell>
            <TableCell>{String(this.measure?.category?.name || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Label</TableCell>
            <TableCell>{String(this.measure.label || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Description</TableCell>
            <TableCell>{String(this.measure.description || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Type</TableCell>
            <TableCell>{String(this.measure.kpiType || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Frequency</TableCell>
            <TableCell>{String(this.measure.frequency || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Difficulty</TableCell>
            <TableCell>{String(this.measure.difficulty || '')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br />
    </PageContainer>
  );
}

const fetchDependencies = ({
  measures,
  match: {
    params: { id }
  }
}) => {
  return measures.actions.show(id, {
    include: 'category'
  });
};

export default provide(MeasuresContext)(dependsOn(fetchDependencies)(Show));
