import React, { Component } from 'react';
import instanceForm from 'containers/shared/instanceForm';
import dependsOn from 'containers/shared/dependsOn';
import { ControlledForm, ErrorBanner, PageContainer, PromiseButton, Autocomplete, DestroyButton, Select } from 'components';
import { Typography, Button, TextField, MenuItem } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ColorPicker from 'material-ui-color-picker';
import { compose } from 'utils';
import withStyles from 'styles';
import { provide, CategoriesContext } from 'contexts';
import * as API from 'api';

export class Form extends Component {
  state = {
    editingMeasures: false
  };

  handleCloseMeasuresDialog = () => {
    this.setState({ editingMeasures: false });
  };

  handleOpenMeasuresDialog = () => {
    this.setState({ editingMeasures: true });
  };

  handleNewMeasure = () => {
    this.props.onFormDataChange({
      ...this.props.formAttributes,
      measures: [...(this.props.formData.measures || []), {}]
    });
  };

  onSuggestionsFetchRequested = async (text, callback) => {
    const { data: categories } = await API.Categories.index({
      options: {
        fields: { categories: 'name' },
        filter: { name: text, topLevel: true },
        page: { number: 1, size: 5 }
      }
    });
    callback(categories.filter(category => category.id !== this.props.formData.id));
  };

  handleDestroyMeasure = (idx) => (destroy) => {
    if (!!destroy && !this.props.formData.measures[idx].id) {
      let newMeasures = [...this.props.formData.measures];
      newMeasures.splice(idx, 1);
      this.props.onFormDataChange({ ...this.props.formAttributes, measures: newMeasures });
    }
  };

  renderMeasure = (measure, idx) => {
    const { destroy } = measure;
    if (!!destroy) {
      return null;
    } else {
      return (
        <Grid container key={idx}>
          <Grid item xs={11}>
            <TextField fullWidth name={`measures[${idx}].name`} />
            <TextField fullWidth name={`measures[${idx}].label`} />
            <TextField fullWidth name={`measures[${idx}].description`} />
            <Select fullWidth name={`measures[${idx}].kpiType`}>
              <MenuItem value="quantitative">Quantitative</MenuItem>
              <MenuItem value="qualitative">Qualitative</MenuItem>
            </Select>
            <Select fullWidth name={`measures[${idx}].frequency`}>
              <MenuItem value="cumulative">Cumulative</MenuItem>
              <MenuItem value="absolute">Absolute</MenuItem>
            </Select>
            <TextField fullWidth name={`measures[${idx}].difficulty`} type="number" inputProps={{ min: '1', max: '5', step: '1' }} />
          </Grid>
          <Grid item xs={1}>
            <DestroyButton onDestroy={this.handleDestroyMeasure(idx)} name={`measures[${idx}].destroy`} />
          </Grid>
        </Grid>
      );
    }
  };

  renderMeasures = (measures) => {
    return measures.map((measure, idx) => {
      return (
        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="subtitle2">{measure.name || "New Measure"}</Typography>
          </AccordionSummary>
          <AccordionDetails className={this.props.classes.measureDetails}>{this.renderMeasure(measure, idx)}</AccordionDetails>
        </Accordion>
      );
    });
  };

  render = () => {
    const { formData, errors, errorStrings, onFormDataChange, onSave, editMode } = this.props;
    return (
      <PageContainer className={this.props.classes.card}>
        <Typography variant="h5">{editMode ? `Edit Category - ${formData.name}` : 'Create a new Category'}</Typography>
        <ControlledForm data={formData} errors={errors} onChange={onFormDataChange} onSubmit={onSave}>
          <ErrorBanner>{errorStrings}</ErrorBanner>
          <TextField fullWidth name="name" />
          <ColorPicker fullWidth name="color" label="Colour"
            value={formData.color || "#000"}
            onChange={color => onFormDataChange({...formData, color})}
            TextFieldProps={{
              value: formData.color || "Automatic"
            }}
          />
          <Autocomplete fullWidth name="parent" onSuggestionsFetchRequested={this.onSuggestionsFetchRequested} />
          <Typography variant="subtitle2">KPI Measures:</Typography>
          {this.renderMeasures(formData.measures || [])}
          <Button color="primary" variant="contained" onClick={this.handleNewMeasure}>
            New KPI measure
          </Button>
          <PromiseButton color="secondary" variant="contained" type="submit">
            Save
          </PromiseButton>
        </ControlledForm>
      </PageContainer>
    );
  };
}

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  },
  measureDetails: {
    flexDirection: 'column'
  }
};

const fetchDependencies = ({ categories, id, editMode }) => {
  if (editMode) {
    return categories.actions.show(id, { include: 'parent,measures' });
  }
};

export default compose(dependsOn(fetchDependencies), instanceForm('categories', { linked: ['parent'], nested: ['measures'] }), withStyles(styles), provide(CategoriesContext))(Form);
