import React, { Component } from 'react';
import instanceForm from 'containers/shared/instanceForm';
import dependsOn from 'containers/shared/dependsOn';
import { ControlledForm, ErrorBanner, PageContainer, PromiseButton, Select, Autocomplete } from 'components';
import { Typography, TextField, MenuItem } from '@material-ui/core';
import { compose } from 'utils';
import withStyles from 'styles';
import { provide, MeasuresContext } from 'contexts';
import * as API from 'api';

export class Form extends Component {

  onSuggestionsFetchRequested = async (text, callback) => {
    const { data: categories } = await API.Categories.index({
      options: {
        fields: { categories: 'id,name' },
        filter: { name: text },
        page: { number: 1, size: 5 }
      }
    });
    callback(categories);
  };

  render = () => {
    const { formData, errors, errorStrings, onFormDataChange, onSave, editMode } = this.props;
    return (
      <PageContainer className={this.props.classes.card}>
        <Typography variant="h5">{editMode ? `Edit KPI Measure - ${formData.name}` : 'Create a new KPI Measure'}</Typography>
        <ControlledForm data={formData} errors={errors} onChange={onFormDataChange} onSubmit={onSave}>
          <ErrorBanner>{errorStrings}</ErrorBanner>
          <TextField fullWidth name="name" />
          <Autocomplete fullWidth name="category" onSuggestionsFetchRequested={this.onSuggestionsFetchRequested} />
          <TextField fullWidth name="label" />
          <TextField fullWidth name="description" />
          <Select fullWidth name="kpiType">
            <MenuItem value="quantitative">Quantitative</MenuItem>
            <MenuItem value="qualitative">Qualitative</MenuItem>
          </Select>
          <Select fullWidth name="frequency">
            <MenuItem value="cumulative">Cumulative</MenuItem>
            <MenuItem value="absolute">Absolute</MenuItem>
          </Select>
          <TextField fullWidth name="difficulty" type="number" inputProps={{ min: '1', max: '5', step: '1' }} />
          <PromiseButton color="secondary" fullWidth variant="contained" type="submit">
            Save
          </PromiseButton>
        </ControlledForm>
      </PageContainer>
    );
  };
}

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  }
};

const fetchDependencies = ({ measures, id, editMode }) => {
  if (editMode) {
    return measures.actions.show(id, { include: 'category' });
  }
};

export default compose(dependsOn(fetchDependencies), instanceForm('measures', {linked: ['category']}), withStyles(styles), provide(MeasuresContext))(Form);
