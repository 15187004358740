import React, { Component } from 'react'
import instanceForm from 'containers/shared/instanceForm'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import qs from 'qs'
import dependsOn from 'containers/shared/dependsOn'
import { CategoryTree, ControlledForm, DatePicker, ErrorBanner, PageContainer, PromiseButton } from 'components'
import { compose, memoizeOne, invertMeasureCategoryTree } from 'utils'
import moment from 'moment'
import withStyles from 'styles'
import { provide, ProjectsContext, MeasuresContext, ValuesContext } from 'contexts'
import Single from './single/Form'

export class Form extends Component {
  treeHelper = memoizeOne(
    invertMeasureCategoryTree
   )
  state = {
    saving: false,
    month: moment().format('YYYY-MM')
  }

  get project() {
    return this.props.projects.selected
  }

  get values() {
    return [
      ...(this.props.formData.values || []),
      ...(this.props.values.list || [])
    ]
  }

  get month() {
    return this.state.month
  }

  reloadExisting = async () => {
    let result = await this.props.values.actions.index({
      include: 'measure',
      params: {projectId: this.props.match.params.projectId},
      filter: { month: this.state.month }
    })
    this.props.onFormDataChange({
      ...this.props.formData,
      values: this.props.formData?.values?.map(value => ({
        ...value,
        id: result.find(({measure: {id}}) => id === value.measure.id)?.id
      }))
    })
    console.log("reloaded")
  }

  changeMonth = ({target: {value: date}}) => {
    const month = moment(date).format('YYYY-MM')
    this.setState({month}, this.reloadExisting)
  }

  valueFor = measureId => {
    return this.values.find(({measure: {id} = {}}) => id === measureId) || {measure: {id: measureId, type: 'measures'}}
  }

  onChangeValueFor = measureId => ({target: {value}}) => {
    this.props.onFormDataChange({
      ...this.props.formData,
      values: [
        ...this.props.formData?.values?.filter(({measure: {id}}) => measureId !== id) || [],
        {...value, measure: {type: 'measures', id: measureId}}
      ]
    })
  }

  setValueIdForMeasure = (measureId, valueId) => this.props.onFormDataChange({
    ...this.props.formData,
    values: this.values.map(value => {
      const {measure: {id} = {}} = value
      if(id === measureId) {
        return {...value, id: valueId}
      } else {
        return value
      }
    })
  })

  saveAll = async () => {
    try {
      this.setState({saving: true})
      const values = this.props.formData.values || []
      for(let i = 0; i < values.length; i++) {
        let {id, measure, ...attributes} = values[i]
        let value = {id,
          ...attributes,
          kpiMonth: this.state.month,
          relationships: {
            measure: {data: {type: 'measures', id: measure.id}},
            project: {data: {type: 'projects', id: this.project.id}}
          }
        }
        if(value.id) {
          await this.props.values.actions.update(value, {include: 'measure'})
        } else {
          let {id, measure:{id: measureId = measure.id}} = await this.props.values.actions.create(value, {include: 'measure'})
          this.setValueIdForMeasure(measureId, id)
        }
      }
      this.props.history.push(`/projects/${this.props.match.params.projectId}`)
    } catch (exception) {
      console.log(exception)
    } finally {
      this.setState({saving: false})
    }
  }

  renderMeasure = (measure) => {
    return <ListItem key={measure.id}>
        <Single
          measure={measure}
          value={this.valueFor(measure.id)}
          onChange={this.onChangeValueFor(measure.id)}
        />
      </ListItem>
  }

  render = () => {
    const {formData, project, errors, errorStrings, onFormDataChange, onSave, editMode, ...props} = this.props
    return <PageContainer className={this.props.classes.card}>
      <ControlledForm data={formData} errors={errors}>
        <ErrorBanner>{errorStrings}</ErrorBanner>
        <Typography variant='h5'>{`KPI Values${this.project?.name ? ` for ${this.project?.name}` : ""}`}</Typography>
        <DatePicker
          fullWidth
          value={new Date(this.month)}
          format="MMMM YYYY"
          label="For Month"
          views={["year","month"]}
          onChange={this.changeMonth}
        />
        <CategoryTree
          {...props}
          root={{categories: this.treeHelper(this.props.projects.selected?.measures || [])}}
          autoexpand={() => true}
          extraChildren={({measures: theseMeasures = []}, options) =>
            theseMeasures.map(measure => this.renderMeasure(measure, options))
          }
        />
        <PromiseButton color="secondary" fullWidth variant="contained" disabled={this.state.saving} onClick={this.saveAll}>
          Save
        </PromiseButton>
      </ControlledForm>
    </PageContainer>
  }
}

const styles = {
  nested: {
    paddingLeft: '1em',
  }
}

const fetchDependencies = ({values, projects, measures, location: {search}, match: {params: {projectId}}}) => {
  const {month = moment().format("YYYY-MM")} = qs.parse(search)
  return Promise.all([
    projects.actions.show(projectId, {
      include: 'measures,measures.category,measures.category.parent'
    }),
    values.actions.index({
      include: 'measure',
      params: {projectId},
      filter: { month }
    })
  ])
}

export default compose (
  dependsOn(fetchDependencies),
  instanceForm('values', {linked: ['measures','projects']}),
  withStyles(styles),
  provide(ProjectsContext, MeasuresContext, ValuesContext)
) (Form)

