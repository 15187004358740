import React, { Component } from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

export default class Show extends Component {
  get kpiType() {
    return this.measure.kpiType
  }

  get measure(){
    return this.props.measure
  }

  render = () => {
    let displayValue = this.props.value[this.kpiType]
    if(this.props.value.link) {
      displayValue = <a
        href={this.props.value.link}
        target="_blank"
        rel="noopener noreferrer"
        >{displayValue}</a>
    }
    return (
      <TableRow>
        <TableCell variant="head">{this.measure.name}</TableCell>
        <TableCell>{displayValue}</TableCell>
      </TableRow>
    )
  }
}

