export function invertMeasureCategoryTree(measures) {
  let root = []
  let categoriesById = {}
  const visitCategory = category => {
    if(categoriesById[category.id]) {
      return categoriesById[category.id]
    } else {
      let place = category.parent ? visitCategory(category.parent).subcategories : root
      let inverted = {
        id: category.id,
        name: category.name,
        color: category.color,
        subcategories: [],
        measures: []
      }
      categoriesById[category.id] = inverted
      place.push(inverted)
      return inverted
    }
  }
  const visitMeasure = measure => {
    const {category, ...props} = measure
    visitCategory(category).measures.push(props)
  }
  measures.forEach(visitMeasure)
  return root
}

