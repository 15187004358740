import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

export default function Line({label, value, className}) {
  const classes = useStyles();
  return (
    <div className={[classes.container, className].join(' ')}>
      <Typography className={classes.item}>{label}</Typography>
      <Typography className={classes.item}>{value} kg</Typography>
    </div>
  );
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: '2%',
    width: '100%',
    borderBottom: '1px solid #0000001c'
  },
  item: {
    flex: 0.5,
    marginTop: '10px'
  }
});
