import React, { Component } from 'react'
import { PieChart, Pie, Cell } from 'recharts'
import { memoizeOne, zeroPad } from 'utils'

export default class MeasureSunburst extends Component {
  calculateDepth = memoizeOne(root => {
    const l = category => [
      category.measures?.length >= 1 ? (category.measures.find(measure => measure.values?.length >= 1) ? 2 : 1) : 0,
      ...(category.subcategories?.map(l) || [])
    ].reduce((a,b) => a > b ? a : b) + 1
    return root.map(l).reduce((a,b) => a > b ? a : b, 0)
  })
  byLayer = memoizeOne(root => {
    let result = []
    let depth = this.calculateDepth(root)
    let add = (layer, portion, item) => {
      if(!result[layer])
        result[layer] = []
      result[layer].push({...item, portion})
      let children = [...(item.measures || []), ...(item.subcategories || []), ...(item.values || [])]
      let unit = children.reduce((s,c) => s + (c.width || 1), 0)
      if(children.length === 0) {
        if(layer + 1 < depth) {
          add(layer + 1, portion, {skip: true})
        }
      } else {
        children.forEach(child => add(layer + 1, portion * (child.width || 1) / unit, child))
      }
    }
    let unit = root.reduce((s,c) => s + (c.width || 1), 0)
    root.forEach(category => add(0,(category.width || 1)/unit,category))
    return result
  })
  calculateWidths = memoizeOne(root => {
    const inner = item => {
      let subcategories = item.subcategories?.map(inner)
      let measures = item.measures?.map(inner)
      let values = item.values?.map(inner)
      let width = item.width || [
        ...(subcategories || []),
        ...(measures || []),
        ...(values || [])
      ].reduce((s, {width}) => s + width, 0) || 1
      return {...item, width, subcategories, measures, values}
    }
    return root.map(inner)
  })

  layerRadius = (index, count) => `${80 * (index + 0.5) / (count + 0.5)}%`

  renderCell = (item,index,layer) => <Cell
    key={String(index)}
    stroke={item.skip ? 'rgba(0,0,0,0)' : '#000'}
    fill={item.skip ? 'rgba(0,0,0,0)' : item.color || fillFor(layer, index)}
   />

  renderCellLabel = ({payload: {name, qualitative, quantitative, link} = {}, cx, cy, midAngle, innerRadius, outerRadius}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const RADIAN = Math.PI / 180
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
    let label = name ?? qualitative ?? quantitative ?? ""
    if(link) {
      label = <a href={link} target="_blank" rel="noopener noreferrer">{label}</a>
    }
    return (
      <text x={x} y={y} fill="black" textAnchor="middle" dominantBaseline="central" fontSize="10">
        {label}
      </text>
    )
  }

  renderLayer = (layer,index,full) => <Pie
     data={layer}
     dataKey="portion"
     key={String(Math.random())}
     innerRadius={this.layerRadius(index, full.length)}
     outerRadius={this.layerRadius(index + 1, full.length)}
     label={this.renderCellLabel}
     labelLine={false}
     isAnimationActive={false}
    >
      {layer.map(this.renderCell)}
    </Pie>

  render = () => {
    const { root, width, height } = this.props
    return <PieChart width={width} height={height}>
        {this.byLayer(this.calculateWidths(root)).map(this.renderLayer)}
      </PieChart>
  }
}

const hsv2rgb = (h,s,v) =>
{
  let f= (n,k=(n+h/(Math.PI/6))%6) => v - v*s*Math.max( Math.min(k,4-k,1), 0);
  return [f(5),f(3),f(1)];
}

const fillFor = (layer,index,layerIndex) => {
  if(layer[index].color) {
    return layer[index].color
  }
  let total = 0
  let begin = null
  let end = null
  for(let i = 0; i < layer.length; i++) {
    let next = total + layer[i].portion
    if(i === index) {
      begin = total
      end = next
    }
    total = next
  }
  let hue = (end + begin) / 2 * total
  let [r,g,b] = hsv2rgb(hue * Math.PI, 1, 1).map(x => zeroPad(2,Math.floor(x * 255).toString(16)))
  return `#${r}${g}${b}`
}

