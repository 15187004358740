import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import * as Containers from 'containers'
import { withRouter } from 'react-router-dom'
import { isProduction } from 'utils'
export class Routes extends Component{

  render = () =>
    <Switch>
      <Route exact path="/tokens/sign_in"             component={Containers.Token.SignIn}/>
      <Route exact path="/tokens/forgot"              component={Containers.Token.Forgot}/>
      <Route exact path="/tokens/reset"               component={Containers.Token.Reset}/>
      <Route exact path="/tokens/accept_invitation"   component={Containers.Token.AcceptInvitation}/>
      <Route exact path="/tokens/confirm"             component={Containers.Token.Confirm}/>
      <Route exact path="/tokens/resend_confirmation" component={Containers.Token.ResendConfirmation}/>
      <Route exact path="/carbon_calculator"          component={Containers.CarbonCalculator.Form} />
      <Route exact path="/dashboard/sunburst"         component={Containers.Dashboard.Sunburst}/>
      <Route exact path="/confirmation_resent"        component={Containers.Status.ConfirmationResent} />
      <Route exact path="/framework"                  component={Containers.Framework.Form} />
      {
        !isProduction() &&
        <Route exact path="/apidocs"                  component={Containers.Docs.ApiDocs} />
      }
      <Route component={() =>  <Redirect to={"/tokens/sign_in"}/>}/>
    </Switch>

}

export default withRouter(Routes)