import React, { Component } from 'react'
import instanceForm from 'containers/shared/instanceForm'
import dependsOn from 'containers/shared/dependsOn'
import {ControlledForm, ErrorBanner, PageContainer, PromiseButton} from 'components'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { compose } from 'utils'
import withStyles from 'styles'
import { provide, FrameworkTemplatesContext, MeasuresContext } from 'contexts'

export class Form extends Component {
  onMeasuresInputChange = (_, value, reason) => {
    this.props.measures.actions.index({
      fields: { measures: 'name' },
      ...(value && {filter: { name: value }})
    })
  }

  render = () => {
    const {formData, errors, errorStrings, onFormDataChange, onSave, editMode} = this.props
    return (
      <PageContainer className={this.props.classes.card}>
        <Typography variant='h5'>{editMode ? `Edit Framework Template - ${formData.name}` : 'Create a new Framework Template'}</Typography>
        <ControlledForm data={formData} errors={errors} onChange={onFormDataChange} onSubmit={onSave}>
          <ErrorBanner>{errorStrings}</ErrorBanner>
          <TextField fullWidth name='name'/>
          <Autocomplete
            multiple
            name="measures"
            getOptionLabel={option => option.name}
            value={formData.measures || []}
            options={this.props.measures.list}
            renderInput={(params) => <TextField
              {...params}
              fullWidth
              label="KPI Measures"
            />}
            onInputChange={this.onMeasuresInputChange}
            onChange={(_,value) => onFormDataChange({...formData, measures: value})}
          />
        <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
        </ControlledForm>
      </PageContainer>
    )
  }

}

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  }
}

const fetchDependencies = ({frameworkTemplates, measures, id, editMode}) => {
  measures.actions.index({
    fields: { measures: 'name' }
  })
  if (editMode) {
    return frameworkTemplates.actions.show(id, {include: 'measures'})
  }
}

export default compose(
  dependsOn(fetchDependencies),
  instanceForm('frameworkTemplates', {linked: ['measures']}),
  withStyles(styles),
  provide(FrameworkTemplatesContext, MeasuresContext),
)(Form)
