import React, { Component } from 'react'
import { Autocomplete, CategoryTree, MeasureSunburst, PageContainer } from 'components'
import CheckBox from '@material-ui/core/Checkbox'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { ResponsiveContainer } from 'recharts'
import dependsOn from 'containers/shared/dependsOn'
import { compose, memoizeOne } from 'utils'
import withStyles from 'styles'
import { provide, CategoriesContext } from 'contexts'
import * as API from 'api'
import { Grid } from '@material-ui/core'

export class Form extends Component {
  state = {
    selected: [],
    frameworkTemplate: {},
    difficultyFilter: null
  };

  handleDownloadFramework = (format) => () => window.open(this.frameworkDownloadPath(format), '_blank');

  getMeasureIds = () => this.state.selected.filter(s => /measures/.test(s)).map(s => s.replace(/[^0-9]/g,''))

  frameworkDownloadPath = (format) => `${window.location.protocol}//${window.location.host}/api/frameworks/${format}?measureIds=${this.getMeasureIds()}`;

  isInList = (list, childId) => (list || []).some((c) => c.id === childId);

  isChecked = (val) => (this.state.selected || []).find((s) => s === this.stringValue(val)) !== undefined;

  stringValue = ({ type, id }) => `${type}_${id}`;

  filterOut = (arr1, arr2) => arr1.filter((s) => !arr2.includes(s));

  getRootCategory = (category) => {
    const categories = this.props.categories.list || [];
    let root,
      run = true,
      idx = 0;
    while (run && categories.length > idx) {
      const current = categories[idx];
      idx++;
      run = !this.isInList(current.subcategories || [], category.id);
      if (!run) root = current;
    }
    return root;
  };

  toggleCategoryAndMeasures = (category) => {
    const selected = [this.stringValue(category)];
    for (let index = 0; index < category.measures?.length; index++) {
      const measure = category.measures[index];
      selected.push(this.stringValue(measure));
    }
    return selected;
  };

  toggleCategory = (category) => {
    let changed = this.toggleCategoryAndMeasures(category);
    if (category.subcategories) {
      for (let index = 0; index < category.subcategories.length; index++) {
        const element = category.subcategories[index];
        changed = changed.concat(this.toggleCategoryAndMeasures(element));
      }
    }
    return changed;
  };

  toggleRoot(root, selected) {
    let values = [];
    const subIds = root.subcategories?.map(this.stringValue) || [];
    const measureIds = root.measures?.map(this.stringValue) || [];
    if(subIds.concat(measureIds).every((v) => selected.includes(v))){
      values.push(this.stringValue(root));
    }
    return values;
  };

  handleToggle = (category, measure) => ({ target: { checked } }) => {
    const selected = this.state.selected;
    let newSelected = [];
    const root = category.subcategories ? category : this.getRootCategory(category);

    if (checked) {
      if (measure) {
        const measureIds = category.measures?.map(this.stringValue) || [];
        newSelected = selected.concat(newSelected);
        newSelected.push(this.stringValue(measure));
        if (measureIds.every((m) => newSelected.includes(m))) {
          if(!category.subcategories) newSelected.push(this.stringValue(category));
          newSelected = newSelected.concat(this.toggleRoot(root, newSelected))
        }
      } else {
        newSelected = selected.concat(this.toggleCategory(category));
        newSelected = newSelected.concat(this.toggleRoot(root, newSelected))
        newSelected = newSelected = selected.concat(newSelected);
      }
    } else {
      if (measure) {
        newSelected = [this.stringValue(measure), this.stringValue(category)];
      } else {
        newSelected = this.toggleCategory(category);
      }
      if (!category.subcategories) {
        newSelected.push(this.stringValue(root));
      }
      newSelected = this.filterOut(selected, newSelected);
    }
    this.setState({ selected: newSelected })
  }

  extractSelected = memoizeOne((() => {
    const extractSelected = (root,selected) => root.map(category => {
      let subcategories = typeof(category.subcategories) === 'object' ?
        extractSelected(category.subcategories,selected) :
        undefined
      let selectedMeasures = typeof(category.measures) === 'object' ?
        category.measures.filter(measure => selected.find(str => this.stringValue(measure) == str)) :
        undefined
      if((selectedMeasures && selectedMeasures.length > 0) || (subcategories && subcategories.length > 0)) {
        return {...category, subcategories, measures: selectedMeasures }
      } else {
        return null
      }
    }).filter(category => category !== null)
    return extractSelected
  })())
  get selectedTree() {
    return this.extractSelected(this.props.categories.list ?? [], this.state.selected)
  }

  onSuggestionsFetchRequested = async (text, callback) => {
    const { data: categories } = await API.FrameworkTemplates.index({
      options: {
        include: 'measures',
        filter: { name: text },
        page: { number: 1, size: 5 }
      }
    });
    callback(categories);
  };

  handleApplyTemplate = () => {
    const measures = this.state.frameworkTemplate?.measures || [];
    this.setState(({selected}) => {
      let newSelected = [...selected, ...measures.map(this.stringValue).filter(measure => !selected.find(ex => ex === measure))]
      const checkCategory = category => {
        if(newSelected.some(ex => ex === this.stringValue(category))) {
          return true
        } else if((category.subcategories ?? []).map(checkCategory)
            .every(x => x) &&
          (category.measures ?? []).every(measure => {
            const ms = this.stringValue(measure)
            return newSelected.find(s => s === ms)
          })
        ) {
          newSelected.push(this.stringValue(category))
          return true
        } else {
          return false
        }
      }
      (this.props.categories.list ?? []).forEach(checkCategory)
      return { selected: newSelected }
    })
  };

  renderMeasure = (category, measure, { expand }) => {
    const checked = this.isChecked(measure);
    return (this.state.difficultyFilter === null || measure.difficulty === this.state.difficultyFilter) ? (
      <ListItem key={`${measure.id}`}>
        <ListItemIcon>
          <CheckBox checked={checked} onChange={this.handleToggle(category, measure)} />
        </ListItemIcon>
        <ListItemText>{measure.name}</ListItemText>
      </ListItem>
    ) : null;
  };

  renderGenerateButton = (format) => (
    <Button color="secondary" className={this.props.classes.generatorButton} onClick={this.handleDownloadFramework(format)}>
      Generate {format.toUpperCase()} Framework
    </Button>
  );

  renderCategoryPrefix = (category) => {
    const checked = this.isChecked(category);
    return <CheckBox checked={checked} onChange={this.handleToggle(category, undefined)} />;
  };

  render = () => {
    const { categories = {}, frameworkTemplates, ...props } = this.props;
    return (
      <PageContainer>
        <Typography variant="h5">Framework Generator</Typography>
        <ResponsiveContainer aspect={1}>
          <MeasureSunburst root={this.selectedTree}/>
        </ResponsiveContainer>
        <Grid container alignItems="flex-end" spacing={3}>
          <Grid item xs={9}>
            <Autocomplete
              fullWidth
              name="frameworkTemplate"
              label="Framework Template"
              onChange={({ target: { value } }) => this.setState({ frameworkTemplate: value })}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            />
          </Grid>
          <Grid item xs={3}>
            <Button color="primary" variant="contained" onClick={this.handleApplyTemplate}>
              Apply Template
            </Button>
          </Grid>
        </Grid>

        <CategoryTree
          {...props}
          root={{ categories: categories.list }}
          autoexpand={() => false}
          prefix={(item) => this.renderCategoryPrefix(item)}
          extraChildren={(category, options) => category.measures?.map((measure) => this.renderMeasure(category, measure, options))}
        />
        {this.renderGenerateButton('pdf')}
        {this.renderGenerateButton('xlsx')}
      </PageContainer>
    );
  };
}

const styles = {
  nested: {
    paddingLeft: '1em'
  },
  generatorButton: {
    width: 'calc(50% - 1em)'
  }
};

const fetchDependencies = ({ categories }, page) => {
  return categories.actions.index({
    filter: { top_level: 'true' },
    include: 'subcategories,measures,subcategories.measures',
    page: page
  });
};

export default compose(dependsOn(fetchDependencies), withStyles(styles), provide(CategoriesContext))(Form);
