import React, { Component } from 'react'
import moment from 'moment'
import { ResponsiveContainer } from 'recharts'
import { DatePicker, MeasureSunburst, PageContainer } from 'components'
import dependsOn from 'containers/shared/dependsOn'
import { provide, ProjectsContext, ValuesContext } from 'contexts'
import { compose, invertMeasureCategoryTree, memoizeOne } from 'utils'

export class Sunburst extends Component {
  state = {
    month: moment().subtract(1, 'months').format('YYYY-MM')
  }
  invertTree = memoizeOne(invertMeasureCategoryTree)
  addValues = memoizeOne((root,values) => {
    const addToMeasure = measure => {
      return {...measure, values: values.filter(({measure: {id}}) => id === measure.id)}
    }
    const inner = category => {
      let measures = category.measures?.map(addToMeasure)
      let subcategories = category.subcategories?.map(inner)
      return {...category, measures, subcategories}
    }
    return root.map(inner)
  })

  get data() {
    return this.addValues(
      this.invertTree(this.props.projects.selected?.measures ?? []),
      this.props.values.list || []
    )
  }

  get month() {
    return this.state.month
  }

  setMonth = async month => {
    this.setState({month})
    loadValues(this.props)(month)
  }

  changeMonth = ({target: {value: date}}) => {
    const month = moment(date).format('YYYY-MM')
    this.setMonth(month)
  }

  render = () => <PageContainer>
      <DatePicker
        fullWidth
        value={new Date(this.month)}
        format="MMMM YYYY"
        views={["year","month"]}
        onChange={this.changeMonth}
      />
      <ResponsiveContainer aspect={1}>
        <MeasureSunburst root={this.data} />
      </ResponsiveContainer>
    </PageContainer>
}

const loadValues = ({values, projectId = 1}) => (month) =>
  values.actions.index({
    include: 'measure',
    filter: { month, projectId }
  })

const fetchDependencies = props => {
  const { projects, projectId = 1 } = props
  const month = moment().subtract(1, 'months').format('YYYY-MM')
  return Promise.all([
    projects.actions.show(projectId || 1, {
      include: 'measures,measures.category,measures.category.parent',
      fields: { measures: 'name,category,color', category: 'name,parent,color' },
    }),
    loadValues({...props, projectId})(month)
  ])
};

export default compose(
  dependsOn(fetchDependencies),
  provide(ProjectsContext, ValuesContext)
)(Sunburst)
