import React, { Component } from 'react';
import { Pagination, FABFixed, ErrorBanner, PageContainer } from 'components';
import dependsOn from 'containers/shared/dependsOn';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import PersonIcon from '@material-ui/icons/Person';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { compose, errorStringsFromError } from 'utils';
import indexView, { hasIndexChanged } from 'containers/shared/indexView';
import { provide, consume, SnackbarContext, MeasuresContext } from 'contexts';

export class List extends Component {
  state = {
    page: 1
  };

  showMeasure = (id) => () => {
    this.props.history.push(`/measures/${id}`);
  };

  editMeasure = (id) => (event) => {
    this.props.history.push(`/measures/${id}/edit`);
    event.stopPropagation();
  };

  deleteMeasure = (id) => (event) => {
    this.props.measures.actions
      .destroy({ id })
      .then(this.props.onDependencyUpdate)
      .catch((error) => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')));
    event.stopPropagation();
  };

  get measures() {
    return this.props.measures.list;
  }

  get errors() {
    let errors = [];
    if (this.props.measures.errors.index) {
      errors = errors.concat(this.props.measures.errors.index);
    }
    if (this.props.measures.errors.destroy) {
      errors = errors.concat(this.props.measures.errors.destroy);
    }
    return errors;
  }

  renderMeasureListItem = ({ id, name }) => (
    <ListItem button onClick={this.showMeasure(id)} key={id}>
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary={name} />
      <ListItemSecondaryAction>
        <IconButton onClick={this.editMeasure(id)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={this.deleteMeasure(id)}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );

  renderErrorMessages = () => <ErrorBanner>{errorStringsFromError(this.errors)}</ErrorBanner>;

  render = () => (
    <PageContainer>
      {this.renderErrorMessages()}
      <Pagination totalPages={this.props.measures.totalPages} page={this.props.page} onPageSelected={this.props.onPageChange} style={{}} linkStyle={{}} />
      <MuiList dense>{this.measures.map(this.renderMeasureListItem)}</MuiList>
      <Pagination totalPages={this.props.measures.totalPages} page={this.props.page} onPageSelected={this.props.onPageChange} style={{}} linkStyle={{}} />
      <FABFixed color="secondary" onClick={() => this.props.history.push('/measures/new')}>
        <AddIcon />
      </FABFixed>
    </PageContainer>
  );
}

const fetchDependencies = ({ measures, page }) => {
  return measures.actions.index({
    page: page,
    fields: { measures: 'name' }
  });
};

export default compose(dependsOn(fetchDependencies, hasIndexChanged), indexView('measures'), provide(MeasuresContext), consume(SnackbarContext))(List);
