// DO NOT edit this file, add extentions in index.js.
// Changes to this file will be overwritten when regenerating

import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export class ApiPathError extends Error {
  constructor(action, params) {
    super(`No route for ${action} matches the provided params: ${JSON.stringify(params)}`)
    this.action = action
    this.params = params
  }
}

export const API = ProtectedJsonAPI.extend(
  '',
  {
    routeNotFound: endpoint => endpoint.path(() => {
      return '/'
    }),
    tag: endpoint => endpoint.path(() => {
      return '/tag'
    }),
  }
)

export const Tokens = ProtectedJsonAPI.extend(
  '',
  {
    forgot: endpoint => endpoint.method('post').path(() => {
      return '/tokens/forgot'
    }),
    reset: endpoint => endpoint.method('post').path(() => {
      return '/tokens/reset'
    }),
    confirm: endpoint => endpoint.method('post').path(() => {
      return '/tokens/confirm'
    }),
    resendConfirmation: endpoint => endpoint.method('post').path(() => {
      return '/tokens/resend_confirmation'
    }),
    acceptInvite: endpoint => endpoint.method('post').path(() => {
      return '/tokens/accept_invite'
    }),
    update: endpoint => endpoint.method('put').path(() => {
      return '/tokens'
    }),
    destroy: endpoint => endpoint.method('delete').path(() => {
      return '/tokens'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/tokens'
    }),
  }
)

export const Users = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/users'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/users'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/users/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/users/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/users/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Values = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(({projectId}) => {
      if (projectId) {
        return `/projects/${projectId}/values`
      }
      return '/values'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/values'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/values/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/values/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/values/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const FrameworkTemplates = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/framework_templates'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/framework_templates'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/framework_templates/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/framework_templates/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/framework_templates/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Measures = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(({projectId}) => {
      if (projectId) {
        return `/projects/${projectId}/measures`
      }
      return '/measures'
    }),
    create: endpoint => endpoint.method('post').path(({projectId}) => {
      if (projectId) {
        return `/projects/${projectId}/measures`
      }
      return '/measures'
    }),
    show: endpoint => endpoint.path(({projectId, id}) => {
      if (projectId && id) {
        return `/projects/${projectId}/measures/${id}`
      }
      if (id) {
        return `/measures/${id}`
      }
      throw new ApiPathError('show', {projectId, id})
    }),
    update: endpoint => endpoint.method('put').path(({projectId, id}) => {
      if (projectId && id) {
        return `/projects/${projectId}/measures/${id}`
      }
      if (id) {
        return `/measures/${id}`
      }
      throw new ApiPathError('update', {projectId, id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({projectId, id}) => {
      if (projectId && id) {
        return `/projects/${projectId}/measures/${id}`
      }
      if (id) {
        return `/measures/${id}`
      }
      throw new ApiPathError('destroy', {projectId, id})
    }),
  }
)

export const Projects = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/projects'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/projects'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/projects/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/projects/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/projects/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Categories = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/categories'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/categories'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/categories/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/categories/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/categories/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Audits = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/audits'
    }),
  }
)
