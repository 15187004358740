export function memoizeOne(plain, comparison = (a,b) => a.length === b.length && !a.find((ae,i) => ae !== b[i])) {
  let last_args = []
  let last_result = undefined
  return (...args) => {
    if(!comparison(last_args, args)) {
      last_result = plain(...args)
    }
    return last_result
  }
}
