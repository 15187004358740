import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import dependsOn from 'containers/shared/dependsOn';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { PageContainer } from 'components';
import { provide, CategoriesContext } from 'contexts';
import { compose } from 'utils';
import withStyles from 'styles';

export class Show extends Component {
  get category() {
    return this.props.categories.selected;
  }

  renderSubcategories = (subcategories) => {
    return (
      <Fragment>
        <Typography variant="subtitle1">Subcategories:</Typography>
        <Table>
          <TableBody>
            {subcategories.map((subcategory) => {
              return (
                <TableRow key={subcategory.id}>
                  <TableCell style={{ width: 30 }} variant="head"></TableCell>
                  <TableCell>
                    <Tooltip title="Open category">
                      <Link to={`/categories/${subcategory.id}`}>{subcategory.name}</Link>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Grid container spacing={2} direction="column">
                      <Grid item>
                        <Typography variant="subtitle2">KPI measures:</Typography>
                      </Grid>
                      {Array.isArray(subcategory.measures) &&
                        subcategory.measures.length > 0 &&
                        subcategory.measures.map((measure) => {
                          return (
                            <Grid item>
                              <Tooltip title="Open KPI measure">
                                <Link to={`/measures/${measure.id}`}>{measure.name}</Link>
                              </Tooltip>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </TableCell>
                  <br />
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Fragment>
    );
  };

  renderMeasures = (measures) => (
    <Table>
      <TableBody>
        {measures.map((measure) => (
          <TableRow key={measure.id}>
            <TableCell style={{ width: 30 }} variant="head"></TableCell>
            <TableCell>
              <Tooltip title="Open measure">
                <Link to={`/measures/${measure.id}`}>{measure.name}</Link>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  render = () => (
    <PageContainer>
      <Typography variant="h4">Category - {this.category.name}</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{ width: 30 }} variant="head">
              Actions
            </TableCell>
            <TableCell>
              <Link to={`/categories/${this.category.id}/edit`}>
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Name</TableCell>
            <TableCell>{String(this.category.name || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Colour</TableCell>
            <TableCell style={this.category.color ? {color: this.category.color} : {}}>{this.category.color}</TableCell>
          </TableRow>
          {this.category.parent && (
            <TableRow>
              <TableCell variant="head">Parent Category</TableCell>
              <TableCell>
                <Tooltip title="Open parent category">
                  <Link to={`/categories/${this.category.parent.id}`}>{this.category.parent.name}</Link>
                </Tooltip>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {this.category.measures && this.category.measures.length > 0 && <Typography variant="subtitle1">KPI measures:</Typography>}
      {this.renderMeasures(this.category.measures || [])}
      {Array.isArray(this.category.subcategories) && this.category.subcategories.length > 0 && this.renderSubcategories(this.category.subcategories)}
      <br />
    </PageContainer>
  );
}

const styles = {
  breadcrumb: {
    color: 'rgba(0,0,0,0.54)',
    textTransform: 'none'
  }
};

const shouldUpdate = (props, prevProps) => {
  return props.location.pathname !== prevProps.location.pathname;
};

const fetchDependencies = ({
  categories,
  match: {
    params: { id }
  }
}) => {
  return categories.actions.show(id, {
    include: 'parent,measures,subcategories,subcategories.measures'
  });
};

export default compose(dependsOn(fetchDependencies, shouldUpdate), withStyles(styles), provide(CategoriesContext))(Show);
