import React, { Component } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import * as Containers from 'containers'
import { Route } from 'containers'
import { withRouter } from 'react-router-dom'
import { Authorization  } from 'utils'

export class Routes extends Component{

  render = () =>
    <Switch>
      <Route exact path="/framework_templates"                    component={Containers.FrameworkTemplate.List}/>
      <Route exact path="/framework_templates/new"                component={Containers.FrameworkTemplate.Form}/>
      <Route exact path="/framework_templates/:id"                component={Containers.FrameworkTemplate.Show}/>
      <Route exact path="/framework_templates/:id/edit"           component={Containers.FrameworkTemplate.Form}/>
      <Route exact path="/categories"                    component={Containers.Category.List}/>
      <Route exact path="/categories/new"                component={Containers.Category.Form}/>
      <Route exact path="/categories/:id"                component={Containers.Category.Show}/>
      <Route exact path="/categories/:id/edit"           component={Containers.Category.Form}/>
      <Route exact path="/dashboard/sunburst"         component={Containers.Dashboard.Sunburst}/>
      <Route exact path="/measures"                    component={Containers.Measure.List}/>
      <Route exact path="/measures/new"                component={Containers.Measure.Form}/>
      <Route exact path="/measures/:id"                component={Containers.Measure.Show}/>
      <Route exact path="/measures/:id/edit"           component={Containers.Measure.Form}/>
      <Route exact path="/projects"                    component={Containers.Project.List}/>
      <Route exact path="/projects/new"                component={Containers.Project.Form}/>
      <Route exact path="/projects/:id"                component={Containers.Project.Show}/>
      <Route exact path="/projects/:id/edit"           component={Containers.Project.Form}/>
      <Route exact path="/projects/:projectId/values" component={Containers.Value.Show}/>
      <Route exact path="/projects/:projectId/values/form"  component={Containers.Value.Form} requires={Authorization.admin}/>
      <Route exact path="/framework"                  component={Containers.Framework.Form} />
      <Route exact path="/profile"                    component={Containers.User.Profile}/>
      <Route exact path="/users"                      component={Containers.User.List} requires={Authorization.admin}/>
      <Route exact path="/users/new"                  component={Containers.User.Form}/>
      <Route exact path="/users/:id"                  component={Containers.User.Show}/>
      <Route exact path="/users/:id/edit"             component={Containers.User.Form}/>
      <Route exact path="/audits"                     component={Containers.Audit.List} requires={Authorization.admin}/>
      <Route exact path="/tokens/sign_in"             component={Containers.Token.SignIn}/>
      <Route exact path="/tokens/forgot"              component={Containers.Token.Forgot}/>
      <Route exact path="/tokens/reset"               component={Containers.Token.Reset}/>
      <Route exact path="/tokens/accept_invitation"   component={Containers.Token.AcceptInvitation}/>
      <Route exact path="/tokens/confirm"             component={Containers.Token.Confirm}/>
      <Route exact path="/tokens/resend_confirmation" component={Containers.Token.ResendConfirmation}/>
      <Route exact path="/forms/demo"                 component={Containers.Forms.Demo}/>
      <Route exact path="/"                           component={() =>  <Redirect to={"/dashboard/sunburst"}/>}/>
      <Route exact path="/not_found"                  component={Containers.Status.NotFound} />
      <Route exact path="/timed_out"                  component={Containers.Status.TimedOut} />
      <Route exact path="/inactive"                   component={Containers.Status.Inactive} />
      <Route exact path="/confirmation_resent"        component={Containers.Status.ConfirmationResent} />
      <Route exact path="/unauthorized"               component={Containers.Status.Unauthorized} />
      <Route exact path="/apidocs"                    component={Containers.Docs.ApiDocs} />
      <Route exact path="/carbon_calculator"          component={Containers.CarbonCalculator.Form} />
      <Route component={Containers.Status.NotFound} />
    </Switch>

}

export default withRouter(Routes)
