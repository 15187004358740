import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import Typography from '@material-ui/core/Typography'
import { CategoryTree, DatePicker, PageContainer } from 'components'
import dependsOn from 'containers/shared/dependsOn'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { compose, invertMeasureCategoryTree, memoizeOne } from 'utils'
import { provide, ProjectsContext, MeasuresContext, ValuesContext } from 'contexts'
import withStyles from 'styles'
import moment from 'moment'
import Single from './single/Show'

export class Show extends Component {
  treeHelper = memoizeOne(invertMeasureCategoryTree)
  state = { month: moment().format('YYYY-MM') }

  get values() {
    return this.props.values.list || []
  }

  valueFor = measureId => {
    return this.values.find(({measure: {id}}) => id === measureId) ||
      {measure: {id: measureId, type: 'measures'}}
  }

  get month() {
    return this.state.month
  }

  changeMonth = ({target: {value: date}}) => {
    const month = moment(date).format('YYYY-MM')
    this.setState({month})
    loadValues(this.props, month)
  }

  renderMeasuresForCategory = ({measures, id}) => measures ?
    <Table key={String(id)}>
      <TableBody>
        {measures.map(measure =>
          <Single
            key={String(measure.id)}
            measure={measure}
            value={this.valueFor(measure.id)}
          />
        )}
      </TableBody>
    </Table> : null

  render = () => {
    const {projects, values, ...props} = this.props
    return <PageContainer className={this.props.classes.card}>
        <Typography >{`KPI Values${projects.selected?.name ? ` for ${projects.selected?.name}` : ''}`}</Typography>
        <Link to={`/projects/${this.props.match.params.projectId}/values/form`} className={this.props.classes.right}>
          <IconButton>
            <EditIcon />
          </IconButton>
        </Link>
        <DatePicker
          fullWidth
          value={new Date(this.month)}
          format="MMMM YYYY"
          label="For Month"
          views={["year","month"]}
          onChange={this.changeMonth}
        />
        <CategoryTree
          {...props}
          root={{categories: this.treeHelper(projects.selected?.measures ?? [])}}
          autoexpand={() => true}
          extraChildren={this.renderMeasuresForCategory}
        />
      </PageContainer>
  }
}

const loadValues = ({values, match: {params: {projectId}}}, month = moment().format('YYYY-MM')) => {
  return values.actions.index({
    include: 'measure',
    params: {projectId},
    filter: {month}
  })
}

const fetchDependencies = props => {
  return Promise.all([
    props.projects.actions.show(props.match.params.projectId, {
      include: 'measures,measures.category,measures.category.parent'
    }),
    loadValues(props)
  ])
}

const styles = {
  nested: {
    paddingLeft: '1em',
  },
  right: {
    float: 'right'
  }
}

export default compose(
  dependsOn(fetchDependencies),
  withStyles(styles),
  provide(ProjectsContext, MeasuresContext, ValuesContext)
)(Show)
