import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import dependsOn from 'containers/shared/dependsOn'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import AssignmentIcon from '@material-ui/icons/Assignment'
import IconButton from '@material-ui/core/IconButton'
import { PageContainer } from 'components'
import { provide, ProjectsContext } from 'contexts'

export class Show extends Component{

  get project(){
    return this.props.projects.selected
  }

  render = () =>
    <PageContainer>
      <Typography variant='h4'>Project - {this.project.name}</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
            <TableCell>
              <Link to={`/projects/${this.project.id}/values`}>
                <IconButton>
                  <AssignmentIcon/>
                </IconButton>
              </Link>
              <Link to={`/projects/${this.project.id}/edit`}>
                <IconButton>
                  <EditIcon/>
                </IconButton>
              </Link>
            </TableCell>
          </TableRow>
            <TableRow>
              <TableCell variant='head'>Name</TableCell>
              <TableCell>{String(this.project.name || '')}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
}

const fetchDependencies = ({projects,  match: { params: { id }}}) => {
  return projects.actions.show(id, {
    include: ''
  })
}

export default provide(ProjectsContext)(dependsOn(fetchDependencies)(Show))