import React, { PureComponent, Fragment, Children } from 'react'
import PropTypes from 'prop-types'
import { humanize, deepGet, deepSet } from 'utils'
/**
 * Goals for this component:
 *
 * * The ability to have multiple form contexts in a single render.
 *   Overcomes the disadvantages of the convention in the FormMixin to
 *   have a single context per component
 *
 * * Copy/Pasteability  - Works with any HTML5-like input component without
 *   the need for customised wrapping components
 *
 * * Supports complex nested properties as the FormMixin currently does
 *
 * * Supports an error context with an identical structure to the context
 *
 * * Plays nicely with enhanced input components (such as those in the material-ui library)
 *
 * * Looks like 'plain-old-react'. We want to be able to remove the ControlledForm and
 * still have a functioning and visually equivalent layout
 *
 * * Multiple contexts can be nested and reference different parts of the context tree
 *
 * * Zero coupling to our data model, redux, and API. All we need is
 *   React a context object, and a change handler
 */
export class ControlledForm extends PureComponent{

  static propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func,
    fieldPropProvider: PropTypes.func
  }

  get formData(){
    return this.path.reduce((memo, part) => memo ? memo[part] : null, this.props.data || {})
  }

  get errors(){
    return this.path.reduce((memo, part) => memo ? memo[part] : null, this.props.errors || {}) || {}
  }

  get path(){
    return this.props.name ? this.props.name.split(/\[(?=\d)|(?!=\d)\]\.?|\./).filter(x => x !== "") : []
  }

  onChange = name => ({target: { type, value, checked }}) =>{
    switch(type){
    case 'checkbox': return this.setAttribute(name, checked)
    case 'number':   return this.setAttribute(name, parseFloat(value) || '')
    default:         return this.setAttribute(name, value)
    }
  }

  value = name =>
    this.attribute(name)

  setAttribute(attribute, value) {
    const updatedContext = deepSet(value, attribute, this.props.data)
    this.props.onChange && this.props.onChange(updatedContext, this.props.data)
  }

  submit = event => {
    if (!event.defaultPrevented){
      if (this.formRef.checkValidity()) {
        event.preventDefault()
        return this.props.onSubmit && this.props.onSubmit(this.props.data)
      }
    }
  }

  handleSubmit = customHandler => event => {
    customHandler && customHandler()
    return this.submit(event)
  }


  attribute = name => {
    return deepGet(name, this.formData)
  }

  fetchError = name => {
    return this.errors[name] || deepGet(name, this.errors)
  }

   // eslint-disable-next-line
  propsForField = (props) => {
    let fieldProps = {}
    if(props.name !== void 0 && !props.uncontrolled){
      /**
       * We've opted in to the being controlled by the form.
       * Let's create some default props
       */
      fieldProps = this.props.fieldPropProvider(this, props)
    }

    return fieldProps
  }


  renderChild = (elm) => {
    if(!(elm && elm.type)) {
      /**
       * We are not a valid input element
       */
      return elm
    }

    let {
      type: Type,
      props: {
        name, errorName, inputRef, uncontrolled, children, ...props
      }
    } = elm

    if(Type === this.constructor){
      /**
       * We are a nested controlled form
       */
      let {onChange, data, errors, ...formProps} = props
      if(name){
        onChange = onChange || this.props.onChange
        data     = data     || this.props.data
        errors   = errors   || this.props.errors
      }
      return <Type onChange={onChange} data={data} errors={errors} name={name} {...formProps}>{children}</Type>
    }
    /**
     * We are a wrapped child
     */

    if (props.type === 'submit') {
      return React.cloneElement(elm, {onClick: this.handleSubmit(elm.props.onClick)})
    }

    // eslint-disable-next-line
    const fieldProps = this.propsForField({...elm.props, propTypes: Type.propTypes})
    children = Children.map(children, child => typeof child !== 'string' ? this.renderChild(child) : child)

    return (
      <Type ref={inputRef} {...{...fieldProps, ...props}}>
        {(children && children.length === 1) ? children[0] : children}
      </Type>
    )
  }

  render = () =>
    this.props.onSubmit ? this.renderAsForm() : this.renderAsFragment()

  renderAsForm = () =>
    <form onSubmit={this.submit} ref={ref => this.formRef = ref}>
      {this.props.autoComplete === false && <input type='hidden' autoComplete="false" />}
      {Children.map(this.props.children, this.renderChild)}
    </form>

  renderAsFragment = () =>
    <Fragment>
      {Children.map(this.props.children, this.renderChild)}
    </Fragment>

}

const propsForMuiField = (form, {type, name, errorName}={}) => {
  const props = {}
  if (type === 'checkbox'){
    props.checked = form.value(name) || false
  }
  else{
    props.value    = form.value(name)
    if(props.value === undefined || props.value === null){
      props.value = ''
    }
  }
  props.onChange    = form.onChange(name)
  const errorText   = form.fetchError(errorName || name)
  if(errorText){
    props.error       = !!errorText
    props.helperText  = errorText
  }
  props.name        = name
  props.label       = humanize(name.replace(/^.*(?:\[(?=\d)|(?!=\d)\]|\.)(.*)$/, "$1"))
  props.placeholder = ""

  return props
}

export default props => <ControlledForm fieldPropProvider={propsForMuiField} {...props}/>