import React, { Component } from 'react'
import { Pagination, FABFixed, ErrorBanner, PageContainer } from 'components'
import dependsOn from 'containers/shared/dependsOn'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import PersonIcon from '@material-ui/icons/Person'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, errorStringsFromError, Authorization } from 'utils'
import indexView, {hasIndexChanged} from 'containers/shared/indexView'
import { provide, consume, SnackbarContext, FrameworkTemplatesContext } from 'contexts'

export class List extends Component{

  state = {
    page: 1
  }

  showFrameworkTemplate = id => () => {
    this.props.history.push(`/framework_templates/${id}`)
  }

  editFrameworkTemplate = id => event => {
    this.props.history.push(`/framework_templates/${id}/edit`)
    event.stopPropagation()
  }

  deleteFrameworkTemplate = id => event => {
    this.props.frameworkTemplates.actions.destroy({id})
                .then(this.props.onDependencyUpdate)
                .catch(error => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get frameworkTemplates(){
    return this.props.frameworkTemplates.list
  }

  get errors(){
    let errors = []
    if(this.props.frameworkTemplates.errors.index){
      errors = errors.concat(this.props.frameworkTemplates.errors.index)
    }
    if(this.props.frameworkTemplates.errors.destroy){
      errors = errors.concat(this.props.frameworkTemplates.errors.destroy)
    }
    return errors
  }

  renderFrameworkTemplateListItem = ({id, name}) =>
    <ListItem button onClick={this.showFrameworkTemplate(id)} key={id}>
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary={name}/>
      { Authorization.admin && 
        <ListItemSecondaryAction>
          <IconButton onClick={this.editFrameworkTemplate(id)}><EditIcon/></IconButton>
          <IconButton onClick={this.deleteFrameworkTemplate(id)}><DeleteIcon/></IconButton>
        </ListItemSecondaryAction>
      }
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <PageContainer>
      {this.renderErrorMessages()}
      <Pagination totalPages={this.props.frameworkTemplates.totalPages} page={this.props.page} onPageSelected={this.props.onPageChange} style={{}} linkStyle={{}}/>
      <MuiList dense>
        {this.frameworkTemplates.map(this.renderFrameworkTemplateListItem)}
      </MuiList>
      <Pagination totalPages={this.props.frameworkTemplates.totalPages} page={this.props.page} onPageSelected={this.props.onPageChange} style={{}} linkStyle={{}}/>
      <FABFixed color='secondary' onClick={() => this.props.history.push('/framework_templates/new')}>
        <AddIcon/>
      </FABFixed>
    </PageContainer>
}

const fetchDependencies = ({frameworkTemplates, page}) => {
  return frameworkTemplates.actions.index({
    page: page,
    fields: {frameworkTemplates: 'name'}
  })
}

export default compose(
  dependsOn(fetchDependencies, hasIndexChanged),
  indexView('frameworkTemplates'),
  provide(FrameworkTemplatesContext),
  consume(SnackbarContext),
)(List)
