import React, { Component } from 'react'
import { Pagination, FABFixed, ErrorBanner, PageContainer } from 'components'
import dependsOn from 'containers/shared/dependsOn'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import PersonIcon from '@material-ui/icons/Person'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, errorStringsFromError } from 'utils'
import indexView, {hasIndexChanged} from 'containers/shared/indexView'
import { provide, consume, SnackbarContext, ProjectsContext } from 'contexts'

export class List extends Component{

  state = {
    page: 1
  }

  showProject = id => () => {
    this.props.history.push(`/projects/${id}`)
  }

  editProject = id => event => {
    this.props.history.push(`/projects/${id}/edit`)
    event.stopPropagation()
  }

  deleteProject = id => event => {
    this.props.projects.actions.destroy({id})
                .then(this.props.onDependencyUpdate)
                .catch(error => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get projects(){
    return this.props.projects.list
  }

  get errors(){
    let errors = []
    if(this.props.projects.errors.index){
      errors = errors.concat(this.props.projects.errors.index)
    }
    if(this.props.projects.errors.destroy){
      errors = errors.concat(this.props.projects.errors.destroy)
    }
    return errors
  }

  renderProjectListItem = ({id, name}) =>
    <ListItem button onClick={this.showProject(id)} key={id}>
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary={name}/>
      <ListItemSecondaryAction>
        <IconButton onClick={this.editProject(id)}><EditIcon/></IconButton>
        <IconButton onClick={this.deleteProject(id)}><DeleteIcon/></IconButton>
      </ListItemSecondaryAction>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <PageContainer>
      {this.renderErrorMessages()}
      <Pagination totalPages={this.props.projects.totalPages} page={this.props.page} onPageSelected={this.props.onPageChange}/>
      <MuiList dense>
        {this.projects.map(this.renderProjectListItem)}
      </MuiList>
      <Pagination totalPages={this.props.projects.totalPages} page={this.props.page} onPageSelected={this.props.onPageChange}/>
      <FABFixed color='secondary' onClick={() => this.props.history.push('/projects/new')}>
        <AddIcon/>
      </FABFixed>
    </PageContainer>
}

const fetchDependencies = ({projects, page}) => {
  return projects.actions.index({
    page: page,
    fields: {projects: 'name'}
  })
}

export default compose(
  dependsOn(fetchDependencies, hasIndexChanged),
  indexView('projects'),
  provide(ProjectsContext),
  consume(SnackbarContext),
)(List)