import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import dependsOn from 'containers/shared/dependsOn'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import MeasureIcon from '@material-ui/icons/BarChart'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { PageContainer } from 'components'
import { provide, FrameworkTemplatesContext } from 'contexts'
import { Authorization } from 'utils'

export class Show extends Component{

  get frameworkTemplate(){
    return this.props.frameworkTemplates.selected
  }

  renderMeasure = measure =>
    <ListItem key={measure.name}>
      <ListItemIcon><MeasureIcon/></ListItemIcon>
      <ListItemText>{measure.name}</ListItemText>
    </ListItem>

  render = () =>
    <PageContainer>
      <Typography variant='h4'>Framework Template - {this.frameworkTemplate.name}</Typography>
      <Table>
        <TableBody>
          { Authorization.admin &&
            <TableRow>
              <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
              <TableCell>
                <Link to={`/framework_templates/${this.frameworkTemplate.id}/edit`}>
                  <IconButton>
                    <EditIcon/>
                  </IconButton>
                </Link>
              </TableCell>
            </TableRow>
          }
          <TableRow>
            <TableCell variant='head'>Name</TableCell>
            <TableCell>{String(this.frameworkTemplate.name || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>KPI Measures</TableCell>
            <TableCell>
              <List>
                {(this.frameworkTemplate.measures || []).map(this.renderMeasure)}
              </List>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
}

const fetchDependencies = ({frameworkTemplates,  match: { params: { id }}}) => {
  return frameworkTemplates.actions.show(id, {
    include: 'measures'
  })
}

export default provide(FrameworkTemplatesContext)(dependsOn(fetchDependencies)(Show))
