import React, { Component, Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Collapse from '@material-ui/core/Collapse';
import CategoryIcon from '@material-ui/icons/KeyboardArrowRight';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

export class CategoryTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedCategories: {}
    };
  }

  toggleCategory = category => (event) => {
    event.stopPropagation()
    this.setState(state => ({
      ...state,
      expandedCategories: {
        ...state.expandedCategories,
        [category.id]: !this.categoryExpanded(category)
      }
    }))
  }

  expandCategory = (category) => (value = true) =>
    this.setState((state) => ({
      ...state,
      expandedCategories: {
        ...state.expandedCategories,
        [category.id]: value
      }
    }));

  findCategory = (id) => {
    let queue = [...this.props.categories];
    while (queue.length > 0) {
      let category = queue.pop();
      if (category.id === id) {
        return category;
      } else {
        queue.push(...(category.subcategories || []));
      }
    }
    return null;
  };

  categoryExpanded = (category) => {
    if (this.state.expandedCategories.hasOwnProperty(category.id)) {
      return this.state.expandedCategories[category.id];
    } else if (this.props.autoexpand) {
      if (this.props.autoexpand(category)) {
        return true;
      } else {
        return !!(category.subcategories || []).find(this.categoryExpanded);
      }
    }
  };

  renderPrefix = (category) => {
    if (this.props.prefix) {
      if (typeof this.props.prefix === 'function') {
        return this.props.prefix(category);
      }
    } else {
      return (
        <ListItemIcon>
          <CategoryIcon />
        </ListItemIcon>
      );
    }
  };

  renderCategory = (category,options) => {
    const expanded = this.categoryExpanded(category)
    const expandIcon = expanded ? <ExpandLess /> : <ExpandMore />
    const expander = this.props.onRowClick ? <IconButton onClick={this.toggleCategory(category)}>{expandIcon}</IconButton> : expandIcon
    const rightIcons = this.props.categoryDecorator ?
      <ListItemSecondaryAction>{this.props.categoryDecorator(category)}{expander}</ListItemSecondaryAction> :
      expander
    return <Fragment key={`categories/${category.id}`}>
      <ListItem button onClick={this.props.onRowClick ? this.props.onRowClick(category) : this.toggleCategory(category)}>
      {this.renderPrefix(category)}
        <ListItemText>
          {category.name}
        </ListItemText>
        {rightIcons}
      </ListItem>
      <Collapse in={expanded}>
        <List component="div" disablePadding className={(this.props.classes || {}).nested}>
          {this.renderBlock(category,options)}
        </List>
      </Collapse>
    </Fragment>
  }

  renderBlock = (category, options) => {
    const expandThis = this.expandCategory(category);
    const expandParent = options.expand || (() => {});
    const nextOptions = {
      ...options,
      expand: (value = true) => {
        expandThis(value);
        if (value) {
          expandParent(value);
        }
      }
    };
    return (
      <Fragment>
        {this.props.extraChildren && this.props.extraChildren(category, nextOptions)}
        {(category.categories || category.subcategories || []).map((subcategory) => this.renderCategory(subcategory, nextOptions))}
      </Fragment>
    );
  };

  render = () => {
    const { root, autoexpand, extraChildren, ...options } = this.props;
    return <List component="div">{this.renderBlock(root, options)}</List>;
  };
}

export default CategoryTree;
